// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manual-entry-tsx": () => import("./../../../src/pages/manualEntry.tsx" /* webpackChunkName: "component---src-pages-manual-entry-tsx" */),
  "component---src-pages-results-jsx": () => import("./../../../src/pages/results.jsx" /* webpackChunkName: "component---src-pages-results-jsx" */),
  "component---src-pages-zim-tsx": () => import("./../../../src/pages/zim.tsx" /* webpackChunkName: "component---src-pages-zim-tsx" */)
}

