import React from 'react';
import { AuthProvider } from 'src/context/AuthContext';
import { BuildersMerchantsProvider } from 'src/context/BuildersMerchantsContext';

interface RootElementProps {
    children: any;
}

const RootElement = ({ children }: RootElementProps) => {
    return (
      <AuthProvider>
        <BuildersMerchantsProvider>{children}</BuildersMerchantsProvider>
      </AuthProvider>
    );
  };

export default RootElement;