import React, { createContext, useState } from 'react';

interface BuildersMerchantsContext {
  swiperContext: SwiperContext;
  searchBarContext: SearchBarContext;
  productsContext: ProductsContext;
  itemSelectionContext: ItemSelectionContext;
}

interface SwiperContext {
  value: number;
  setValue: Function;
}

interface SearchBarContext {
  value: string;
  setValue: Function;
}

interface ProductsContext {
  value: ProductSearchItem[];
  setValue: Function;
}

interface ItemSelectionContext {
  items: Map<string, ProductItem>;
  setItems: Function;
  selectedItem: ProductItem;
  setSelectedItem: Function;
}

const defaultState: BuildersMerchantsContext = {
  swiperContext: {
    value: 0,
    setValue: () => {},
  },
  searchBarContext: {
    value: '',
    setValue: () => {},
  },
  productsContext: {
    value: [],
    setValue: () => {},
  },
  itemSelectionContext: {
    items: new Map<string, ProductItem>(),
    selectedItem: null,
    setItems: () => { },
    setSelectedItem: () => {},
  }
};

export const BuildersMerchantsContext = createContext(defaultState);

export const BuildersMerchantsProvider = ({ children }: BuildersMerchantsProviderProps) => {
  const [swipeValue, setSwipeValue] = useState(0);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [productSearchItems, setProductSearchItems] = useState<ProductSearchItem[]>([]);
  const [items, setItems] = useState<Map<string, ProductItem>>();
  const [selectedItem, setSelectedItem] = useState<ProductItem>();

  return (
    <BuildersMerchantsContext.Provider
      value={{ 
        swiperContext: {
          value: swipeValue,
          setValue: setSwipeValue,
        },
        searchBarContext: {
          value: searchBarValue,
          setValue: setSearchBarValue,
        },
        productsContext: {
          value: productSearchItems,
          setValue: setProductSearchItems,
        },
        itemSelectionContext: {
          items: items,
          selectedItem: selectedItem,
          setItems: setItems,
          setSelectedItem: setSelectedItem,
        }
      }}
    >
      {children}
    </BuildersMerchantsContext.Provider>
  );
};

interface BuildersMerchantsProviderProps {
  children: any;
}
