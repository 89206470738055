import React from "react"

export const ORDER_MESSAGES = {
  PAYING: null,
  REGISTERED: (
    <div>
      You can't send an order without a monthly subscription. If you'd like to
      place an order, please sign up to a monthly subscription{" "}
      <a href="https://www.sortit.ai/plans-pricing">here</a>.
    </div>
  ),
  VISITOR: (
    <div>
      You can't send an order without a monthly subscription. If you'd like to
      place an order, please sign up to a monthly subscription{" "}
      <a href="https://www.sortit.ai/plans-pricing">here</a>.
    </div>
  ),
}
