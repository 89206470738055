import React, { createContext, useEffect, useState } from "react"
import { ORDER_MESSAGES } from "./constants"
import type { AuthProviderProps, AuthConfiguration, AuthContextInterface } from "."

const defaultState = {
  currentUserConfiguration: {
    adsFree: false,
    isAbleToOrder: false,
    orderMessage: null,
  },
  user: null
}

export const AuthContext = createContext<AuthContextInterface>(defaultState);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState(null);

  const MOCKED_RESPONSE = {
    type: "PAYING",
    email: "vsyrotiuk@gmail.com",
    name: "Vlad Syrotiuk"
  }

  const request = (id) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ ...MOCKED_RESPONSE, id })
      }, 1500)
    }).then(response => {
      setUser(response)
    })
  }

  useEffect(() => {
    const userId = new URLSearchParams(window.location.search).get('userId');
    const storedUserId = window.localStorage.getItem('id');

    if (userId || storedUserId) {
      const finalUserId = userId || storedUserId;
      window.localStorage.setItem('id', finalUserId);
      request(finalUserId);
    } else {
      setUser({ type: 'VISITOR', email: null, id: null, name: null});
    }
  }, []);

  const authConfiguration: AuthConfiguration = {
    userType: {
      PAYING: {
        adsFree: true,
        isAbleToOrder: true,
        orderMessage: ORDER_MESSAGES.PAYING,
      },
      REGISTERED: {
        adsFree: false,
        isAbleToOrder: false,
        orderMessage: ORDER_MESSAGES.REGISTERED,
      },
      VISITOR: {
        adsFree: false,
        isAbleToOrder: false,
        orderMessage: ORDER_MESSAGES.VISITOR,
      },
    },
  }
  const currentUserConfiguration = authConfiguration.userType[user?.type]

  return (
    <AuthContext.Provider value={{ currentUserConfiguration, user }}>
      {children}
    </AuthContext.Provider>
  )
}

